import api from '@apiInstance';

export default {
  add(body) {
    return api.post('header_banners', body);
  },
  get(id) {
    return api.fetch('header_banners/' + id);
  },
  list(params) {
    return api.fetch('/header_banners', { params });
  },
  update(body) {
    return api.patch('header_banners', body);
  },
  delete(id) {
    return api.remove('header_banners', id);
  },
};
